import "@gouch/to-title-case";
import "../index.scss";

import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Form,
  Popconfirm,
  Input,
  InputNumber,
  DatePicker,
  Divider,
  Button,
  message,
  Spin,
  Modal,
  Select,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { requestWithAuth } from "../../services/api";
import { filterStates, fundingRounds } from "../../utils/constants";
import {
  getValue,
  formatEventName,
  dateFilterOptions,
  splitNumberWithCommas,
  compare,
} from "../../utils/general";
import CommonTable from "../../components/CommonTable";
import TopSection from "../../components/TopSection";
import CompanySuggest from "../../components/CompanySuggest";
import { EditableCell, EditableRow } from "../../components/EditableComponents";

let params = {};

moment.fn.toJSON = function () {
  return this.format("YYYY-MM-DD");
};

const FormItems = ({ formVals, form, isUpdate, eventTypes }) => {
  useEffect(() => {
    form.resetFields();
  }, [formVals, form]);

  return [
    <Form.Item
      name="b2b_id"
      label="b2b ID"
      initialValue={formVals.b2b_id}
      required
      rules={[
        {
          required: true,
          message: "Choose b2b ID",
        },
      ]}
    >
      <Input placeholder="b2b ID" />
    </Form.Item>,

    <Form.Item name="cik" label="CIK" initialValue={formVals.cik}>
      <Input placeholder="CIK" disabled={isUpdate} />
    </Form.Item>,

    <Form.Item
      name="type"
      label="Type"
      required
      rules={[
        {
          required: true,
          message: "Choose Type",
        },
      ]}
      initialValue={formVals.type}
    >
      <Select style={{ width: "100%" }} disabled={isUpdate}>
        {eventTypes.map(({ value, text }) => (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,

    <Form.Item
      name="org_name"
      label="Org Name"
      initialValue={formVals.org_name}
    >
      <CompanySuggest
        value={{ label: formVals.org_name, value: formVals.ai_org_id }}
        disabled={isUpdate}
      />
    </Form.Item>,

    <Form.Item
      name="stock_ticker"
      label="Stock Ticker"
      initialValue={formVals.stock_ticker}
    >
      <Input placeholder="Stock Ticker" disabled={isUpdate} />
    </Form.Item>,

    <Form.Item
      name={"date"}
      label="Date"
      initialValue={formVals.date ? moment(formVals.date) : undefined}
      required
      rules={[
        {
          required: true,
          message: "Enter Date",
        },
      ]}
    >
      <DatePicker
        placeholder="Date"
        style={{ width: "100%" }}
        disabled={isUpdate}
      />
    </Form.Item>,

    <Form.Item
      name="monetary_value"
      label="Monetary Value"
      required
      initialValue={
        formVals.monetary_value
          ? splitNumberWithCommas(formVals.monetary_value)
          : null
      }
      rules={[
        {
          required: true,
          message: "Enter Monetary Value",
        },
      ]}
    >
      <InputNumber
        style={{ width: "100%" }}
        placeholder="Monetary Value"
        autoComplete="_away"
        disabled={isUpdate}
      />
    </Form.Item>,

    <Form.Item
      name="filing_url"
      label="Filing URL"
      initialValue={formVals.filing_url}
    >
      <Input placeholder="Filing URL" disabled={isUpdate} />
    </Form.Item>,

    <Form.Item name="state" label="State" initialValue={formVals.state}>
      <Select style={{ width: "100%" }} disabled={isUpdate}>
        {filterStates.map(({ value, text }) => (
          <Select.Option key={value} value={text}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,

    <Form.Item
      name="funding_round"
      label="Funding Round"
      initialValue={formVals.funding_round}
    >
      <Select style={{ width: "100%" }} disabled={isUpdate}>
        {fundingRounds.map(({ value, text }) => (
          <Select.Option key={value} value={text}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,

    <Form.Item
      name="data_source_name"
      label="Data Source Name"
      initialValue={formVals.data_source_name}
    >
      <Input
        placeholder="Data Source Name"
        autoComplete="_away"
        disabled={isUpdate}
      />
    </Form.Item>,
  ];
};

const FormModal = ({
  form,
  isUpdate,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
  eventTypes,
}) => {
  const [formVals, setFormVals] = useState({});

  useEffect(() => {
    if (isUpdate) {
      setFormVals({ ...recordFormVals });
    }
  }, [recordFormVals, isUpdate]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };
        if (formValues.org_name && formValues.org_name.label) {
          // formValues.ai_org_id = formValues.org_name.key;
          formValues.org_name = formValues.org_name.label;
        } else {
          delete formValues.org_name;
        }
        if (formValues.date) formValues.date = moment(formValues.date);
        else delete formValues.date;
        if (formValues.monetary_value)
          formValues.monetary_value = formValues.monetary_value.toString();
        else delete formValues.monetary_value;

        if (isUpdate) {
          handleUpdate(formVals.id, formValues);
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error("Please fill the mandatory fileds");
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? "Update Insider Event" : "New Insider Event"}
      visible
      width={800}
      okText={isUpdate ? "Update" : "Create"}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="medium" spinning={isModalLoading}>
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 13 }}
          form={form}
          // initialValues={formVals || {}}
          // name="control-hooks"
          // onFinish={onFinish}
        >
          {/* {formItems(formVals, form, isUpdate)} */}
          <FormItems
            formVals={formVals}
            form={form}
            isUpdate={isUpdate}
            eventTypes={eventTypes}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

const InsiderEventsEditor = () => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: "",
    order: "",
  });
  const [eventsData, setEventsData] = useState([]);
  const [eventsCount, setEventsCount] = useState(0);
  const [allClearFilters, setAllClearFilters] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [eventTypes, setEventTypes] = useState([]);
  const [companySearch, setCompanySearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const searchInputRef = useRef(null);
  const [form] = Form.useForm();

  const fetchEventsAggs = useCallback(() => {
    const params = {
      key: "event_types",
      lookup_type: "n2",
    };
    requestWithAuth("field-lookup", "GET", params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const events = [];
          response.result.map((event) => {
            if (
              event &&
              (event.includes("insider") || event.includes("news"))
            ) {
              events.push({ value: event, text: formatEventName(event) });
            }
            return "";
          });
          events.sort(compare);
          setEventTypes(events);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const fetchEventsData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      from: (current - 1) * pageSize,
      impact_type: "single",
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth("vault-events", "GET", newParams, null).then((response) => {
      if (response && response.results) {
        setEventsData(response.results);
        setEventsCount(response.count);
        setListPagination((prevState) => ({
          ...prevState,
          total: response.count,
        }));
      }
      setIsLoading(false);
    });
  }, [current, pageSize, searchQuery]);

  useEffect(() => {
    return () => {
      // if (allClearFilters && Object.keys(allClearFilters).length)
      //   Object.keys(allClearFilters).map((key) => allClearFilters[key]());
      params = {};
      setSortInfo({
        column: "",
        order: "",
      });
      setListPagination({
        current: 1,
        pageSize: 50,
      });
      setSearchQuery("");
    };
  }, []);
  
  useEffect(() => {
    fetchEventsAggs();
  }, [ fetchEventsAggs]);

  useEffect(() => {
    fetchEventsData();
  }, [fetchEventsData]);

  //   useEffect(() => {
  //     if (listPagination.total === 0) return;
  //     fetchEventsData();
  //   }, [listPagination.pageSize, listPagination.current]);

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const handleCreate = (fields) => {
    setIsModalLoading(true);
    if (fields.monetary_value)
      fields.monetary_value = fields.monetary_value.replaceAll(",", "");
    requestWithAuth("vault-events", "POST", null, fields)
      .then((response) => {
        if (response) {
          fetchEventsData();
          setShowFormModal(false);
        }
        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };
  const handleSearchQuery = (searchValue) => {
    
    setSearchQuery(searchValue || "");
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
    
  };

  const handleUpdate = (recordId, fields) => {
    if (fields.monetary_value)
      fields.monetary_value = fields.monetary_value.replaceAll(",", "");
    setIsModalLoading(true);
    requestWithAuth(`vault-events/${recordId}`, "PUT", null, fields)
      .then((response) => {
        if (response) {
          fetchEventsData();
          setShowFormModal(false);
          setIsUpdate(false);
        }
        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleSave = (row) => {
    setIsLoading(true);
    const fields = {};
    fields.b2b_id = row.b2b_id;

    requestWithAuth("vault-events/" + row.id, "PUT", null, fields).then(
      (response) => {
        if (response) {
          fetchEventsData();
        }
        setIsLoading(false);
      }
    );
  };

  const handleDelete = (recordId, eventId) => {
    setIsLoading(true);
    requestWithAuth("vault-events/" + recordId, "DELETE", null, null).then(
      (response) => {
        if (response && response.code === 200)
          message.success("Insider event deleted");
        setIsLoading(false);
        fetchEventsData();
      }
    );
  };

  const handleDeleteMultiple = () => {
    const body = {
      id_list: selectedIds,
    };
    setIsLoading(true);
    requestWithAuth("delete-events", "POST", null, body).then((response) => {
      if (response && response.code === 200)
        message.success("Insider event deleted");
      setSelectedIds([]);
      setSelectedRowKeys([]);
      setIsLoading(false);
      fetchEventsData();
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={companySearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setCompanySearch(e.target.value ? [e.target.value] : []);
            setAllClearFilters({
              ...allClearFilters,
              [dataIndex]: clearFilters,
            });
          }}
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "var(--color-primary)" : undefined,
        }}
      />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
    let searchData = selectedKeys[0];
    if (searchData !== undefined)
      if (dataIndex === "Company") params.org_name = searchData;
    setAllClearFilters({
      ...allClearFilters,
      [dataIndex]: clearFilters,
    });
    confirm();
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    setCompanySearch("");
    clearFilters();
    if (dataIndex === "Company") delete params.org_name;
    fetchEventsData();
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedIds = [];
    selectedIds = selectedRows.map((obj) => {
      return obj.id;
    });
    setSelectedRowKeys(selectedRowKeys);
    setSelectedIds(selectedIds);
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    Object.keys(filtersArg).reduce((obj, key) => {
      const filt = { ...obj };
      let value = "";
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);
      if (key === "state") {
        if (!value) {
          delete params.state;
        } else {
          filt.state = value.split("\n").join(",");
          params.state = value.split("\n").join(",");
        }
      } else if (key === "type") {
        if (value === null || value === "") {
          delete params.type;
        } else {
          filt.type = value.split("\n").join(",");
          params.type = value.split("\n").join(",");
        }
      } else if (key === "date") {
        const date = [];
        date.push(value);
        if (value === null || value === "") {
          delete params.date_filter;
        } else {
          filt.date_filter = value;
          params.date_filter = value;
        }
      }
      return "";
    }, {});
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === "descend" ? "-" + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: "",
        order: "",
      });
      delete params.ordering;
    }
    if (
      pagination.pageSize === listPagination.pageSize &&
      pagination.current === listPagination.current
    ) {
      fetchEventsData();
    }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = [
    {
      title: "b2b ID",
      dataIndex: "b2b_id",
      key: "b2b_id",
      width: 180,
      editable: true,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "b2b_id" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "CIK",
      dataIndex: "cik",
      key: "cik",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "cik" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "type" && sortInfo.order,
      filters: eventTypes,
      filterMultiple: true,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {formatEventName(val)}
          </div>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "org_name",
      key: "org_name",
      width: 180,
      ...getColumnSearchProps("Company"),
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "org_name" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Stock Ticker",
      dataIndex: "stock_ticker",
      key: "stock_ticker",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "stock_ticker" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Date",
      width: 120,
      dataIndex: "date",
      key: "date",
      filters: dateFilterOptions,
      filterMultiple: false,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "date" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {moment(val).format("MM/DD/YY")}
          </div>
        );
      },
    },
    {
      title: "Monetary Value",
      width: 160,
      dataIndex: "monetary_value",
      key: "monetary_value",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "monetary_value" && sortInfo.order,
      render: (val) => {
        return (
          <div style={{ width: "100%", wordBreak: "break-word" }}>{val}</div>
        );
      },
    },
    {
      title: "Filing URL",
      dataIndex: "filing_url",
      key: "filing_url",
      width: 200,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "filing_url" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 100,
      sorter: true,
      filters: filterStates,
      filterMultiple: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "state" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Funding Round",
      dataIndex: "funding_round",
      key: "funding_round",
      width: 140,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "funding_round" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Data Source Name",
      dataIndex: "data_source_name",
      key: "data_source_name",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "data_source_name" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <>
          <span
            style={{ color: "var(--color-primary)" }}
            onClick={() => showUpdateForm(record)}
          >
            Edit
          </span>
          <Divider type="vertical" />
          <Popconfirm
            title="Delete?"
            onConfirm={() => handleDelete(record.id, record.events_id)}
          >
            <span
              style={{ color: "var(--color-primary)" }}
              onClick={(e) => e.preventDefault()}
            >
              Delete
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        // onClick: (e)=>e.stopPropagation()
      }),
    };
  });

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={eventsCount}
          toggleCreateForm={toggleFormModal}
          selectedRowKeys={selectedRowKeys}
          handleDeleteMultiple={handleDeleteMultiple}
          handleSearchQuery={handleSearchQuery}
          searchValue={searchQuery}
        />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={editableColumns}
            data={eventsData}
            components={components}
            rowClassName={() => "editable-row"}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => `${rec.events_id}${rec.id}`}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            rowEventHandlers={showUpdateForm}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          eventTypes={eventTypes}
          recordFormVals={recordFormVals}
          onClose={() => {
            form.resetFields();
            setIsUpdate(false);
            setShowFormModal(false);
          }}
        />
      )}
    </>
  );
};

export default InsiderEventsEditor;
