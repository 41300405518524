import "../index.scss";

import React, { useCallback, useEffect, useState } from "react";
import { Popconfirm, message, Spin, Modal, Checkbox, Switch } from "antd";
import { requestWithAuth } from "../../services/api";
import CommonTable from "../../components/CommonTable";
import TopSection from "../../components/TopSection";
import { splitNumberWithCommas } from "../../utils/general";

let params = {
  ordering: "-employee_headcount",
};
//let searchQuery = "";

const MergeModal = ({ toggleShowModal, fetchCompanyMergeData }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [listPagination, setListPagination] = useState({
  //     total: 0,
  //     pageSize: 50,
  //     current: 1,
  // });
  const [sortInfo, setSortInfo] = useState({
    column: "employee_headcount",
    order: "descend",
  });
  const [companyData, setCompanyData] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [primaryCompany, setPrimaryCompany] = useState({});
  const [secondaryCompanies, setSecondaryCompanies] = useState([]);
  const [showSearchValue, setShowSearchValue] = useState(false);
  const [HighlightedRows, setHighlightedRows] = useState([]);
  const [isAllOrg, setIsAllOrg] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { column, order } = sortInfo;

  // useEffect(() => {
  //     if (listPagination.total === 0) return;
  //     fetchCompanyData();
  // }, [listPagination.pageSize, listPagination.current]);

  const fetchCompanyData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: 200,
      from: 0,
      is_all_org: isAllOrg ? 1 : 0,
    };

    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth("vault-company", "GET", newParams, null).then(
      (response) => {
        if (response && response.results) {
          setCompanyData(response.results);
          setCompanyCount(response.count);
          // setListPagination((prevState) => ({
          //     ...prevState,
          //     total: response.count,
          // }));
        }
        setIsLoading(false);
      }
    );
  }, [isAllOrg, searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      fetchCompanyData();
    }
  }, [isAllOrg, fetchCompanyData, column, order,searchQuery]);

  const handleMerge = (primary, secondary) => {
    setIsLoading(true);
    let body = {
      ...primary,
      secondary_org_data: [...secondary],
    };
    requestWithAuth("vault-org-merge", "POST", null, body).then((response) => {
      setIsLoading(false);
      if (response && response.code === 200) {
        message.success("Merged Successfully");
        handleFormReset();
        toggleShowModal(false);
        fetchCompanyMergeData();
      } else if (
        response &&
        response.error &&
        response.error.code === 400 &&
        response.error.message
      ) {
        Modal.error({
          title: "Error!",
          content: response.error.message,
          onOk: () => {
            return;
          },
        });
        if (response.error.error_ids_list)
          setHighlightedRows(response.error.error_ids_list);
      } else message.error("Something went wrong.Please try after sometime.");
    });
    setIsLoading(false);
  };

  const handleSearchQuery = (searchValue) => {
    //searchQuery = searchValue;
    setSearchQuery(searchValue || "");
    if (searchValue) {
      setShowSearchValue(true);
      //fetchCompanyData();
    }
  };

  const handleFormReset = () => {
    params = {};
    // setListPagination({
    //     current: 1,
    //     pageSize: 50
    // });
    setSortInfo({
      column: "",
      order: "",
    });
    setPrimaryCompany({});
    setSecondaryCompanies([]);
    setSearchQuery("");
    setShowSearchValue(false);
    setHighlightedRows([]);
    setIsAllOrg(true);
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === "descend" ? "-" + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: "",
        order: "",
      });
      delete params.ordering;
    }
    // if (
    //     pagination.pageSize === listPagination.pageSize &&
    //     pagination.current === listPagination.current
    // ) {
    //     fetchCompanyData();
    // }
    // setListPagination({
    //     ...pagination,
    //     pageSize: pagination.pageSize,
    //     current: pagination.current,
    // });
    fetchCompanyData();
    setPrimaryCompany({});
    setSecondaryCompanies([]);
  };

  const getCompanyIndex = (id) => {
    return companyData.findIndex((obj) => obj.id === id);
  };

  const onPrimaryCompanyChange = (e, record, rowIndex) => {
    /*  -> if another company is already selected as primary, uncheck it
               and reset 'primaryCompany' state variable.
            -> if the selected company is already selected as secondary, 
                do uncheck & disable and remove it from 'secondaryCompany'
                state variable
            -> update primary & secondary in company data 
             **/
    setHighlightedRows([]);
    if (primaryCompany && primaryCompany.primary_ai_org_id) {
      const previousPrimaryIndex = getCompanyIndex(
        primaryCompany.primary_ai_org_id
      );
      companyData[previousPrimaryIndex].is_primary_checked = false;
    }
    if (
      secondaryCompanies.some(
        (obj) => obj.secondary_ai_org_id === record.ai_org_id
      )
    ) {
      const previousSecondaryIndex = getCompanyIndex(record.ai_org_id);
      companyData[previousSecondaryIndex].is_secondary_checked = false;
      let updatedSecondary = secondaryCompanies.filter(
        (obj) => obj.secondary_ai_org_id !== record.ai_org_id
      );
      setSecondaryCompanies(updatedSecondary);
    }
    companyData[rowIndex].is_primary_checked = true;
    setCompanyData([...companyData]);

    let selected = {
      primary_ai_org_id: record.ai_org_id,
      primary_b2b_org_id: record.b2b_org_id,
      primary_org_name: record.name,
    };
    setPrimaryCompany(selected);
  };

  const onSecondaryCompanyChange = (e, record, rowIndex) => {
    setHighlightedRows([]);
    companyData[rowIndex].is_secondary_checked = e.target.checked;
    if (e.target.checked) {
      let selected = {
        secondary_ai_org_id: record.ai_org_id,
        secondary_b2b_org_id: record.b2b_org_id,
        secondary_org_name: record.name,
      };
      setSecondaryCompanies([...secondaryCompanies, selected]);
    } else {
      const selected = secondaryCompanies.filter(
        (obj) => obj.secondary_ai_org_id !== record.ai_org_id
      );
      setSecondaryCompanies(selected);
    }
    setCompanyData([...companyData]);
  };

  const onMerge = () => {
    if (
      primaryCompany &&
      Object.keys(primaryCompany).length > 0 &&
      secondaryCompanies &&
      secondaryCompanies.length > 0
    ) {
      Modal.confirm({
        content: "Confirm Selection",
        onOk: () => {
          handleMerge(primaryCompany, secondaryCompanies);
        },
      });
    } else {
      message.error("Please Select Primary and Secondary Companies");
      return;
    }
  };

  const columns = [
    {
      title: "Primary",
      key: "primary",
      dataIndex: "is_primary_checked",
      width: 50,
      render: (value, record, rowIndex) => (
        <Checkbox
          value={record}
          onChange={(e) => onPrimaryCompanyChange(e, record, rowIndex)}
          checked={value}
        />
      ),
    },
    {
      title: "Merge",
      key: "merge",
      dataIndex: "is_secondary_checked",
      width: 50,
      render: (value, record, rowIndex) => (
        <Checkbox
          value={record}
          onChange={(e) => onSecondaryCompanyChange(e, record, rowIndex)}
          disabled={record.is_primary_checked}
          checked={value}
        />
      ),
    },
    {
      title: "Companies",
      key: "name",
      dataIndex: "name",
      width: 180,
    },
    {
      title: "Website",
      key: "url",
      dataIndex: "url",
      width: 100,
      render: (val) => {
        return (
          <div style={{ width: "100%", display: "block" }}>
            <a href={`${val}`} target="_blank" rel="noopener noreferrer">
              {val}
            </a>
          </div>
        );
      },
    },
    {
      title: "People",
      key: "employee_headcount",
      dataIndex: "employee_headcount",
      width: 100,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "employee_headcount" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {splitNumberWithCommas(val)}
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      title="Merge Companies"
      visible
      width={900}
      okText="Merge"
      onOk={onMerge}
      onCancel={() => {
        handleFormReset();
        toggleShowModal(false);
      }}
    >
      <div className="people-editor">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "60px auto",
            alignItems: "center",
          }}
        >
          <Switch
            checkedChildren="ALL"
            unCheckedChildren="ANY"
            checked={isAllOrg}
            onChange={(checked) => setIsAllOrg(checked)}
            style={{ marginBottom: "20px" }}
          />
          <TopSection
            count={companyCount}
            showNewButton={false}
            placeholder="Search company names, short names, alt names, url, naics codes, stock ticker or linkedin "
            handleSearchQuery={handleSearchQuery}
            handleFormReset={handleFormReset}
            showSearch
            searchValue={searchQuery}
          />
        </div>
        {showSearchValue ? (
          <CommonTable
            columns={columns}
            data={companyData}
            loading={isLoading}
            onTableChange={handleTableChange}
            rowClassName={(record) =>
              HighlightedRows.includes(record.ai_org_id)
                ? "highlighted-row"
                : ""
            }
          />
        ) : (
          <div className="company-search-warning">
            Please Search Company For Merging!
          </div>
        )}
      </div>
    </Modal>
  );
};

const CompanyMerge = () => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [selectedIds, setSelectedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyMergeData, setCompanyMergeData] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  
  const fetchCompanyMergeData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      page_size: pageSize,
      page: current
    };
    requestWithAuth("vault-org-merge", "GET", newParams, null).then(
      (response) => {
        if (response && response.results) {
          setCompanyMergeData(response.results);
          setCompanyCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
        }
        setIsLoading(false);
      }
    );
  }, [pageSize, current]);
  useEffect(() => {
    fetchCompanyMergeData();
  }, [fetchCompanyMergeData]);

  //   useEffect(() => {
  //     if (listPagination.total === 0) return;
  //     fetchCompanyMergeData();
  //   }, [listPagination.pageSize, listPagination.current]);

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const toggleShowModal = (flag) => {
    setShowMergeModal(flag);
  };

  const handleDelete = (ids) => {
    let body = {
      pk_ids: ids,
    };
    setIsLoading(true);
    requestWithAuth("vault-org-merge/delete-multiple", "POST", null, body).then(
      (response) => {
        if (response && response.code === 200)
          message.success("Company merge data deleted");
        setSelectedIds([]);
        setSelectedRowKeys([]);
        setIsLoading(false);
        fetchCompanyMergeData();
      }
    );
  };
  
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedIds = [];
    selectedIds = selectedRows.map((obj) => {
      return obj.id;
    });
    setSelectedRowKeys(selectedRowKeys);
    setSelectedIds(selectedIds);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Primary Company ID",
      dataIndex: "primary_ai_org_id",
      key: "primary_ai_org_id",
      width: 150,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Primary Company Name",
      dataIndex: "primary_org_name",
      key: "primary_org_name",
      width: 150,
    },
    {
      title: "Secondary Company ID",
      dataIndex: "secondary_ai_org_id",
      key: "secondary_ai_org_id",
      width: 150,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Secondary Company Name",
      dataIndex: "secondary_org_name",
      key: "secondary_org_name",
      width: 150,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <>
          <Popconfirm
            title="Remove?"
            onConfirm={() => handleDelete([record.id])}
          >
            <span
              style={{ color: "var(--color-primary)" }}
              onClick={(e) => e.preventDefault()}
            >
              Remove
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={companyCount}
          showSearch={false}
          toggleCreateForm={toggleShowModal}
          deleteButtonText={"Remove"}
          selectedRowKeys={selectedRowKeys}
          handleDeleteMultiple={() => handleDelete(selectedIds)}
                  />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={companyMergeData}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => `${rec.events_id}${rec.id}`}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
          />
        </Spin>
      </div>
      {showMergeModal && (
        <MergeModal
          toggleShowModal={toggleShowModal}
          fetchCompanyMergeData={fetchCompanyMergeData}
        />
      )}
    </>
  );
};

export default CompanyMerge;
