import "@gouch/to-title-case";
import "../index.scss";

import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Popconfirm,
  Input,
  InputNumber,
  DatePicker,
  Divider,
  message,
  Spin,
  Modal,
  Select,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { EditableCell, EditableRow } from "../../components/EditableComponents";
import { requestWithAuth } from "../../services/api";
import { filterStates, fundingRounds } from "../../utils/constants";
import {
  getValue,
  formatEventName,
  dateFilterOptions,
  splitNumberWithCommas,
  compare,
} from "../../utils/general";
import CommonTable from "../../components/CommonTable";
import TopSection from "../../components/TopSection";
import CompanySuggest from "../../components/CompanySuggest";
import CreatableMultiSelect from "../../components/CreatableSelect";

let params = {};
//let searchQuery = "";

moment.fn.toJSON = function () {
  return this.format("YYYY-MM-DD");
};

const FormItems = ({ formVals, form, isUpdate, eventTypes }) => {
  useEffect(() => {
    form.resetFields();
  }, [formVals, form]);

  return [
    <Form.Item
      name="org_name"
      label="Org Name"
      initialValue={formVals.org_name}
    >
      <CompanySuggest
        value={{ label: formVals.org_name, value: formVals.ai_org_id }}
      />
    </Form.Item>,

    <Form.Item
      name="investor_name"
      label="Investor Name"
      initialValue={formVals.investor_name}
    >
      <Input placeholder="Investor Name" disabled={isUpdate} />
    </Form.Item>,

    <Form.Item
      name="type"
      label="Type"
      required
      rules={[
        {
          required: true,
          message: "Choose Type",
        },
      ]}
      initialValue={formVals.type}
    >
      <Select style={{ width: "100%" }} disabled={isUpdate}>
        {eventTypes.map(({ value, text }) => (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,

    <Form.Item
      name="stock_ticker"
      label="Stock Ticker"
      initialValue={formVals.stock_ticker}
    >
      <Input placeholder="Stock Ticker" disabled={isUpdate} />
    </Form.Item>,

    <Form.Item
      name={"date"}
      label="Date"
      initialValue={formVals.date ? moment(formVals.date) : undefined}
      required
      rules={[
        {
          required: true,
          message: "Enter Date",
        },
      ]}
    >
      <DatePicker
        placeholder="Date"
        style={{ width: "100%" }}
        disabled={isUpdate}
      />
    </Form.Item>,

    <Form.Item
      name="monetary_value"
      label="Monetary Value"
      required
      initialValue={
        formVals.monetary_value
          ? splitNumberWithCommas(formVals.monetary_value)
          : null
      }
      rules={[
        {
          required: true,
          message: "Enter Monetary Value",
        },
      ]}
    >
      <InputNumber
        style={{ width: "100%" }}
        placeholder="Monetary Value"
        autoComplete="_away"
        disabled={isUpdate}
      />
    </Form.Item>,

    <Form.Item
      name="filing_url"
      label="Filing URL"
      initialValue={formVals.filing_url}
    >
      <Input placeholder="Filing URL" disabled={isUpdate} />
    </Form.Item>,

    <Form.Item name="state" label="State" initialValue={formVals.state}>
      <Select style={{ width: "100%" }} disabled={isUpdate}>
        {filterStates.map(({ value, text }) => (
          <Select.Option key={value} value={text}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,

    <Form.Item
      name="funding_round"
      label="Funding Round"
      initialValue={formVals.funding_round}
    >
      <Select style={{ width: "100%" }} disabled={isUpdate}>
        {fundingRounds.map(({ value, text }) => (
          <Select.Option key={value} value={text}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,

    <Form.Item
      name="data_source_name"
      label="Data Source Name"
      initialValue={formVals.data_source_name}
    >
      <Input
        placeholder="Data Source Name"
        autoComplete="_away"
        disabled={isUpdate}
      />
    </Form.Item>,
  ];
};

const FormModal = ({
  form,
  isUpdate,
  eventTypes,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
}) => {
  const [formVals, setFormVals] = useState({});

  useEffect(() => {
    if (isUpdate) {
      setFormVals({ ...recordFormVals });
    }
  }, [recordFormVals, isUpdate]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };
        if (formValues.org_name && formValues.org_name.label) {
          formValues.ai_org_id = formValues.org_name.key;
          formValues.org_name = formValues.org_name.label;
        } else {
          delete formValues.org_name;
        }
        if (formValues.date) formValues.date = moment(formValues.date);
        else delete formValues.date;
        if (formValues.monetary_value)
          formValues.monetary_value = formValues.monetary_value.toString();
        else delete formValues.monetary_value;

        if (isUpdate) {
          handleUpdate(formVals.id, formValues);
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error("Please fill the mandatory fileds");
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? "Update Company Event" : "New Company Event"}
      visible
      width={800}
      okText={isUpdate ? "Update" : "Create"}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="medium" spinning={isModalLoading}>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 13 }} form={form}>
          <FormItems
            formVals={formVals}
            form={form}
            isUpdate={isUpdate}
            eventTypes={eventTypes}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

const CompanyEventsEditor = () => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [companyEventsData, setCompanyEventsData] = useState([]);
  const [companyEventsCount, setCompanyEventsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: "",
    order: "",
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [companySelect, setCompanySelect] = useState([]);
  const [allClearFilters, setAllClearFilters] = useState({});
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filteredInfoState, setFilteredInfoState] = useState([]);
  const [filteredDate, setFilteredDate] = useState('')
  const [searchQuery, setSearchQuery] = useState("");
  const [form] = Form.useForm();
  const { column, order } = sortInfo;

  const fetchEventsAggs = useCallback(() => {
    const params = {
      key: "event_types",
      lookup_type: "n2",
    };
    requestWithAuth("field-lookup", "GET", params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const events = [];
          response.result.map((event) => {
            if (
              event &&
              !event.includes("insider") &&
              !event.includes("news")
            ) {
              events.push({ value: event, text: formatEventName(event) });
            }
            return "";
          });
          events.sort(compare);
          setEventTypes(events);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const fetchCompanyEventsData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      impact_type: "multiple",
      page_size: pageSize,
      from: (current - 1) * pageSize,
    };
    if (searchQuery) newParams.org_name = searchQuery;
    let url = "vault-events";
    if (companySelect && companySelect.length > 0) {
      companySelect.map((company, index) => {
        url = `${url}${index === 0 ? "?" : "&"}`;
        url = `${url}${
          company.__isNew__ ? "org_name_contains=" : "org_name_exact="
        }${company.label}`;
        return "";
      });
    }
    requestWithAuth(url, "GET", newParams, null).then((response) => {
      if (response && response.results) {
        setCompanyEventsData(response.results);
        setCompanyEventsCount(response.count);
        setListPagination((prevState) => ({
          ...prevState,
          total: response.count,
        }));
      }
      setIsLoading(false);
    });
  }, [pageSize, current, companySelect, searchQuery]);

  useEffect(()=>{
    fetchEventsAggs()
  },[fetchEventsAggs])

  // useEffect(() => {
  //   fetchEventsAggs();
  //   // params.ordering = sortInfo.order === 'descend' ? '-' + sortInfo.column : sortInfo.column;
  //   fetchCompanyEventsData();
  // }, [fetchEventsAggs, fetchCompanyEventsData]);

  //   useEffect(() => {
  //     if (listPagination.total === 0) return;
  //     fetchCompanyEventsData();
  //   }, [listPagination.pageSize, listPagination.current]);

  useEffect(() => {
    return () => {
      // if (allClearFilters && Object.keys(allClearFilters).length)
      //   Object.keys(allClearFilters).map((key) => allClearFilters[key]());
      params = {};
      setSortInfo({
        column: "",
        order: "",
      });
      // params.ordering = sortInfo.order === 'descend' ? '-' + sortInfo.column : sortInfo.column;
      setListPagination({
        current: 1,
        pageSize: 50,
      });
      // setAllClearFilters({});
      setFilteredEvents([]);
      setFilteredInfoState([]);
      setFilteredDate("");
      setSearchQuery("");
      setCompanySelect([]);
    };
  }, []);

  useEffect(() => {
    fetchCompanyEventsData();
  }, [
    companySelect,
    fetchCompanyEventsData,
    column,
    order,
    filteredInfoState,
    filteredEvents,
    filteredDate,
  ]);

  const handleSave = (row) => {
    setIsLoading(true);
    const fields = {};
    fields.state = row.state;
    fields.type = row.type;
    fields.date = row.date;
    fields.monetary_value = row.monetary_value;
    fields.org_name = row.org_name;
    fields.data_source_name = row.data_source_name;
    fields.stock_ticker = row.stock_ticker;

    requestWithAuth("vault-events/" + row.id, "PUT", null, fields).then(
      (response) => {
        if (response) {
          fetchCompanyEventsData();
        }
        setIsLoading(false);
      }
    );
  };

  const handleDelete = (recordId, eventId) => {
    setIsLoading(true);
    requestWithAuth("vault-events/" + recordId, "DELETE", null, null).then(
      (response) => {
        if (response && response.code === 200)
          message.success("Company event deleted");
        setIsLoading(false);
        fetchCompanyEventsData();
      }
    );
  };

  const handleCreate = (fields) => {
    setIsModalLoading(true);
    if (fields.monetary_value)
      fields.monetary_value = fields.monetary_value.replaceAll(",", "");
    requestWithAuth("vault-events", "POST", null, fields)
      .then((response) => {
        if (response) {
          fetchCompanyEventsData();
          setShowFormModal(false);
        }

        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUpdate = (recordId, fields) => {
    setIsModalLoading(true);
    if (fields.monetary_value)
      fields.monetary_value = fields.monetary_value.replaceAll(",", "");
    requestWithAuth(`vault-events/${recordId}`, "PUT", null, fields)
      .then((response) => {
        if (response) {
          fetchCompanyEventsData();
          setShowFormModal(false);
          setIsUpdate(false);
        }
        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleDeleteMultiple = () => {
    const body = {
      id_list: selectedIds,
    };
    setIsLoading(true);
    requestWithAuth("delete-events", "POST", null, body).then((response) => {
      if (response && response.code === 200)
        message.success("Company event deleted");
      setSelectedIds([]);
      setSelectedRowKeys([]);
      setIsLoading(false);
      fetchCompanyEventsData();
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="column-search">
        <CreatableMultiSelect
          id="org_name"
          placeholder="Search Company"
          isMulti
          from="network"
          wrapperClassName="company-search"
          onSelect={onCompanySelect}
          value={companySelect}
        />
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined />,
  });

  const onCompanySelect = (selected) => {
    setCompanySelect(selected);
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    Object.keys(filtersArg).reduce((obj, key) => {
      const filt = { ...obj };
      let value = "";
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);

      if (key === "state") {
        if (!value) {
          setFilteredInfoState([]);
          delete params.state;
        } else {
          setFilteredInfoState(value.split(","));
          filt.state = value;
          params.state = value;
        }
      } else if (key === "type") {
        if (!value) {
          setFilteredEvents([]);
          delete params.type;
        } else {
          setFilteredEvents(value.split(","));
          filt.type = value;
          params.type = value;
        }
      } else if (key === "date") {
        if (!value) {
          delete params.date_filter;
          setFilteredDate("")
        } else {
          filt.date_filter = value;
          params.date_filter = value;
          setFilteredDate(value)
        }
      }
      return "";
    }, {});
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === "descend" ? "-" + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: "",
        order: "",
      });
      delete params.ordering;
      // params.ordering = sortInfo.order === 'descend' ? '-' + sortInfo.column : sortInfo.column
    }
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchCompanyEventsData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedIds = [];
    selectedIds = selectedRows.map((obj) => {
      return obj.id;
    });
    setSelectedRowKeys(selectedRowKeys);
    setSelectedIds(selectedIds);
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Company",
      dataIndex: "org_name",
      key: "org_name",
      width: 180,
      editable: true,
      ...getColumnSearchProps("Company"),
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "org_name" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    // {
    //     title: 'Employee Head Count',
    //     dataIndex: 'employee_head_count',
    //     key: 'employee_head_count',
    //     width: 180,
    //     editable: true,
    //     sorter: true,
    //     sortDirections: ['ascend', 'descend'],
    //     sortOrder: sortInfo.column === 'employee_head_count' && sortInfo.order,
    //     render: val => {
    //         return (
    //             <div style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}>{val}</div>
    //         );
    //     },
    // },
    {
      title: "Investor Name",
      dataIndex: "investor_name",
      key: "investor_name",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "investor_name" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "type" && sortInfo.order,
      filters: eventTypes,
      filteredValue: filteredEvents || [],
      filterMultiple: true,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {formatEventName(val)}
          </div>
        );
      },
    },

    {
      title: "Stock Ticker",
      dataIndex: "stock_ticker",
      key: "stock_ticker",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "stock_ticker" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Date",
      width: 120,
      dataIndex: "date",
      key: "date",
      filters: dateFilterOptions,
      filteredValue: filteredDate? [filteredDate] : null,
      filterMultiple: false,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "date" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {moment(val).format("MM/DD/YY")}
          </div>
        );
      },
    },

    {
      title: "Monetary Value",
      width: 160,
      dataIndex: "monetary_value",
      key: "monetary_value",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "monetary_value" && sortInfo.order,
      render: (val) => {
        return (
          <div style={{ width: "100%", wordBreak: "break-word" }}>{val}</div>
        );
      },
    },
    {
      title: "Filing URL",
      dataIndex: "filing_url",
      key: "filing_url",
      width: 200,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "filing_url" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 100,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "state" && sortInfo.order,
      filters: filterStates,
      filteredValue: filteredInfoState || [],
      filterMultiple: true,
      editable: true,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Funding Round",
      dataIndex: "funding_round",
      key: "funding_round",
      width: 140,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "funding_round" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },

    {
      title: "Data Source Name",
      dataIndex: "data_source_name",
      key: "data_source_name",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "data_source_name" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <>
          <span
            style={{ color: "var(--color-primary)" }}
            onClick={() => showUpdateForm(record)}
          >
            Edit
          </span>
          <Divider type="vertical" />
          <Popconfirm
            title="Delete?"
            onConfirm={() => handleDelete(record.id, record.events_id)}
          >
            <span
              style={{ color: "var(--color-primary)" }}
              onClick={(e) => e.preventDefault()}
            >
              Delete
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleSearchQuery = (searchValue) => {
      setSearchQuery(searchValue || "");
      setListPagination((prevState) => ({
        ...prevState,
        current: 1,
      }));
  };

  const handleFormReset = () => {
    if (allClearFilters && Object.keys(allClearFilters).length)
      Object.keys(allClearFilters).map((key) => allClearFilters[key]());
    params = {};
    setSortInfo({
      column: "",
      order: "",
    });
    // params.ordering = sortInfo.order === 'descend' ? '-' + sortInfo.column : sortInfo.column;
    setListPagination({
      current: 1,
      pageSize: 50,
    });
    setAllClearFilters({});
    setFilteredEvents([]);
    setFilteredInfoState([]);
    setFilteredDate('');
    setSearchQuery("");
    setCompanySelect([]);
    //fetchCompanyEventsData();
  };

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        // onClick: (e)=>e.stopPropagation(),
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
      <div className="people-editor company-events">
        <TopSection
          count={companyEventsCount}
          toggleCreateForm={toggleFormModal}
          selectedRowKeys={selectedRowKeys}
          handleDeleteMultiple={handleDeleteMultiple}
          placeholder="Search company"
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          showSearch
          searchValue={searchQuery}
        />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={editableColumns}
            data={companyEventsData}
            components={components}
            rowClassName={() => "editable-row"}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => `${rec.events_id}${rec.id}`}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            rowEventHandlers={showUpdateForm}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          eventTypes={eventTypes}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          onClose={() => {
            form.resetFields();
            setIsUpdate(false);
            setShowFormModal(false);
          }}
        />
      )}
    </>
  );
};

export default CompanyEventsEditor;
