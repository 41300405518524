//import "../index.scss";

import React, { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import { ExportOutlined, LoadingOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

import { requestWithAuth } from "../../services/api";

let dataToExport = [];
// let csvData = [];
const ExportCsv = (props) => {
  const { downloadFileName = "Csv_Table", url } = props;
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const buttonRef = useRef(null);

  const totalPage = Math.ceil(props.totalCount / 100);

  useEffect(() => {
    if (csvData && csvData.length && buttonRef) {
      buttonRef.current.link.click();
      setCsvData([]);
    }
  }, [csvData]);

  const getReports = async (count) => {
    setIsExportLoading(true);
    if (count <= totalPage) {
      const params = { ...props.filterParams };
      params.page_size = 100;
      params.page = count;
      if (props.searchQuery) {
        params.q = props.searchQuery;
      }
      requestWithAuth(url, "GET", params, null)
        .then((response) => {
          if (response && response.results) {
            dataToExport = [...dataToExport, ...response.results];
            getReports(count + 1);
          }
        })
        .catch((e) => {
          console.log("Export-CSV :", e);
        });
    } else {
      setCsvData([...dataToExport]);
      setIsExportLoading(false);
      return;
    }
  };

  return (
    <>
      {csvData && csvData.length > 0 ? (
        <CSVLink
          filename={`${downloadFileName}.csv`}
          className="export-csv-reports"
          data={csvData}
          ref={buttonRef}
        >
          <Button
            icon={isExportLoading ? <LoadingOutlined /> : <ExportOutlined />}
            type="primary"
            className="new-button"
            disabled={isExportLoading}
          >
            Export CSV
          </Button>
        </CSVLink>
      ) : (
        <Button
          icon={isExportLoading ? <LoadingOutlined /> : <ExportOutlined />}
          type="primary"
          className="new-button"
          disabled={isExportLoading}
          onClick={() => {
            dataToExport = [];
            getReports(1);
          }}
        >
          Export CSV
        </Button>
      )}
    </>
  );
};

export default ExportCsv;
