import { Table } from "antd";

const CommonTable = (props) => {
  const {
    pagination,
    pageSize,
    columns,
    data,
    onTableChange,
    components,
    rowClassName = "",
    rowKey,
    rowSelection,
    rowEventHandlers,
    scroll = null,
    className = "",
    size = "middle",
  } = props;

  const handleTableChange = (pagination, filters, sorter) => {
    if (onTableChange) {
      onTableChange(pagination, filters, sorter);
    }
  };

  const paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    // changed page size options to 50,100 and 200
    pageSizeOptions: ["20", "50", "100", "200"],
    /** by default defaultPageSize is 10,
     * to change that to current size or 200
     */
    defaultPageSize: pageSize,
    // set selected page size of user even after signout
    ...pagination,
  };

  return (
    <div className="table-class">
      <Table
        columns={columns}
        pagination={pagination ? paginationProps : false}
        pageSize={pageSize}
        dataSource={data}
        onChange={handleTableChange}
        components={components}
        className={className}
        rowClassName={rowClassName}
        rowKey={rowKey}
        rowSelection={rowSelection}
        scroll={scroll || { x: "max-content" }}
        onRow={(rec) => {
          return {
            onClick: (e) => {
              e.stopPropagation();
              if (rowEventHandlers) rowEventHandlers(rec);
            },
          };
        }}
        size={size}
      />
    </div>
  );
};
export default CommonTable;
