import React from 'react';
import { Spin } from 'antd';

const Progress = (props) => {
  const { id = 'progress-spin' } = props;
  return (
    <div
      id={id}
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 2,
          width: '100%',
          height: '100%',
          textAlign: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }}
      > 
        <Spin style={{ position: 'fixed', marginTop: '200px' }} size="large" ></Spin>
        
      </div>
    </div>
  );
};

export default Progress;
