import "./index.scss";
import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import {
  ProfileOutlined,
  SolutionOutlined,
  ProjectOutlined,
  DatabaseOutlined,
  BankOutlined,
  MergeCellsOutlined,
  AlignCenterOutlined,
  DashboardOutlined,
  SnippetsOutlined,
  VideoCameraOutlined,
  CommentOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
  ImportOutlined,
  ExportOutlined,
  FileOutlined,
  LinkOutlined,
  FormOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import logo from "../../assets/icons/ai-logo.svg";
import logotype from "../../assets/icons/ai-admin-logotype.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const rootSubmenuKeys = [
  "data-editor",
  "user-editor",
  "dashboard",
  "team-editor",
  "import-export",
  "account",
];

const SiderComponent = (props) => {
  const [openKeys, setOpenKeys] = useState(["data-editor"]);
  const [selectedKeys, setselectedKeys] = useState(["peopleEditor"]);

  const location = useLocation();
  const { isNexisAdmin } = props;

  const menuData = [
    {
      key: "data-editor",
      icon: <ProfileOutlined />,
      title: "Data Editor",
      hidden: isNexisAdmin,
      children: [
        {
          key: "vault",
          path: "/data-editor/vault",
          icon: <SolutionOutlined />,
          title: "People Editor",
        },
        {
          key: "org-events",
          path: "/data-editor/org-events",
          icon: <ProjectOutlined />,
          title: "Company Events Editor",
        },
        {
          key: "insider-events",
          path: "/data-editor/insider-events",
          icon: <DatabaseOutlined />,
          title: "Insider Events Editor",
        },
        {
          key: "company",
          path: "/data-editor/company",
          icon: <BankOutlined />,
          title: "Company Editor",
        },
        {
          key: "company-merge",
          path: "/data-editor/company-merge",
          icon: <MergeCellsOutlined />,
          title: "Company Merge",
        },
        {
          key: "profile-edits",
          path: "/data-editor/profile-edits",
          icon: <EditOutlined />,
          title: "Profile Edits",
        },
      ],
    },
    {
      key: "user-editor",
      icon: <UserAddOutlined />,
      title: "Users Editor",
      children: isNexisAdmin
        ? [
            {
              key: "users",
              path: "/user-editor/users",
              icon: <SolutionOutlined />,
              title: "Users",
            },
          ]
        : [
            {
              key: "users",
              path: "/user-editor/users",
              icon: <SolutionOutlined />,
              title: "Users",
            },
            {
              key: "users-mapping",
              path: "/user-editor/users-mapping",
              icon: <UserAddOutlined />,
              title: "Users Mapping",
            },
            {
              key: "credits-log",
              path: "/user-editor/credits-log",
              icon: <DollarOutlined />,
              title: "Credits Log",
            },
            {
              key: "user-setup-response",
              path: "/user-editor/user-setup-response",
              icon: <QuestionCircleOutlined />,
              title: "User Setup Response",
            },
          ],
    },
    {
      key: "referrerCode",
      path: "/referrercode",
      icon: <AlignCenterOutlined />,
      title: "Referrer Code",
    },
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      title: "Dashboard",
      hidden: isNexisAdmin,
      children: [
        {
          key: "weekly-tip",
          path: "/dashboard/weekly-tip",
          icon: <SnippetsOutlined />,
          title: "Weekly Tip",
        },
        {
          key: "suggested-video",
          path: "/dashboard/suggested-video",
          icon: <VideoCameraOutlined />,
          title: "Suggested Video",
        },
      ],
    },
    {
      key: "comments",
      path: "/comments",
      icon: <CommentOutlined />,
      title: "Comments",
      hidden: isNexisAdmin,
    },
    {
      key: "team-editor",
      path: "/team-editor",
      icon: <TeamOutlined />,
      title: "Team Editor",
    },
    {
      key: "import-export",
      icon: <FileOutlined />,
      title: "Import/Export",

      children: [
        {
          key: "contact-exports",
          path: "/import-export/contact-exports",
          icon: <ExportOutlined />,
          title: "Contact Exports",
        },
        {
          key: "contact-imports",
          path: "/import-export/contact-imports",
          icon: <ImportOutlined />,
          title: "Contact Imports",
        },
        {
          key: "crm-export-tracks",
          path: "/import-export/crm-export-tracks",
          hidden: isNexisAdmin,
          icon: <LinkOutlined />,
          title: "Crm Export Tracks",
        },
      ],
    },
    {
      key: "reports",
      path: "/reports",
      icon: <FormOutlined />,
      title: "Reports",
    },
    {
      key: "account",
      icon: <UserOutlined />,
      title: "Account",
      hidden: isNexisAdmin,
      children: [
        {
          key: "settings",
          path: "/account/settings/base",
          icon: <></>,
          title: "Account Settings",
        },
      ],
    },
  ];

  useEffect(() => {
    setOpenKeys([location.pathname.split("/")[1]]);
    setselectedKeys([location.pathname.split("/")[2]]);
  }, [location]);

  useEffect(() => {
    /** clicked an item when the menu was collapsed,
     * on expanding the view, show the selected item Submenu as expanded
     */
    if (!props.collapsed) {
      const key = location.pathname.split("/")[1];
      setOpenKeys([key]);
    }
  }, [props.collapsed, location.pathname]);

  const onOpenChange = (keys) => {
    /** on a menu click  all the other menus gets collapsed  */
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // const onMenuItemSelect = ({ item, key, keyPath, selectedKeys, domEvent }) =>{
  //     setselectedKeys([key])
  // }

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={props.collapsed}
        width={256}
        theme="light"
        className={"sider"}
      >
        <div className="header">
          <Link to="/">
            <img className="logo" src={logo} alt="logo" />
            <img className="logotype" src={logotype} alt="logotype" />
          </Link>
        </div>
        <Menu
          mode="inline"
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          // onSelect={onMenuItemSelect}
          // defaultOpenKeys={defaultKeys}
          onOpenChange={onOpenChange}
        >
          {menuData.map((menu) => {
            if (!menu.hidden) {
              if (menu.children) {
                return (
                  <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                    {menu.children.map((child) => {
                      return (
                        !child.hidden && (
                          <Menu.Item key={child.key} icon={child.icon}>
                            <Link to={child.path} />
                            {child.title}
                          </Menu.Item>
                        )
                      );
                    })}
                  </SubMenu>
                );
              } else {
                return (
                  <Menu.Item key={menu.key} icon={menu.icon}>
                    <Link to={menu.path} />
                    {menu.title}
                  </Menu.Item>
                );
              }
            }
            return null;
          })}
        </Menu>
      </Sider>
      <div style={{ width: props.collapsed ? 80 : 256 }} />
      {/** Dummy div to adjust the spacing */}
    </>
  );
};

export default SiderComponent;
