import "../index.scss";

import React, { useCallback, useEffect, useState } from "react";
import { Spin } from "antd";

import { requestWithAuth } from "../../services/api";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import CommonTable from "../../components/CommonTable";
import TopSection from "../../components/TopSection";

let params = {};

function UserSetupResponse() {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [userResponseData, setUserResponseData] = useState([]);
  const [userResponseDataCount, setUserResponseDataCount] = useState(0);

  const fetchUserResponseData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };

    requestWithAuth("vault-user-setup-questions", "GET", newParams, null).then(
      (response) => {
        if (response && response.results) {
          setUserResponseData(response.results);
          setUserResponseDataCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      }
    );
  }, [pageSize, current]);

  useEffect(() => {
    fetchUserResponseData();
  }, [fetchUserResponseData]);

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const columns = [
    {
      title: "User Email",
      width: 120,
      dataIndex: "email",
      key: "email",
      //   sorter: true,
      //   sortDirections: ["ascend", "descend"],
      //   sortOrder: sortInfo.column === "created" && sortInfo.order,
    },

    {
      title: "Reason for Purchase",
      dataIndex: "response",
      key: "reasons",
      width: 200,
      render: (val, record) => {
        return (
          <div
            style={{
              width: "100%",
              wordBreak: "break-word",
              display: "block",
            }}
          >
            {record && record.response.reasons
              ? record.response.reasons.map((item, index) => {
                  return (
                    <>
                      {index !== 0 && (
                        <>
                          ,<br />
                        </>
                      )}
                      {item}
                    </>
                  );
                })
              : ""}
          </div>
        );
      },
    },
    {
      title: "Additional Users",
      dataIndex: "response",
      key: "colleague_support",
      width: 200,
      render: (val, record) => {
        return (
          <div
            style={{
              width: "100%",
              wordBreak: "break-word",
              display: "block",
            }}
          >
            {record && record.response.colleague_support
              ? record.response.colleague_support.map((item) => {
                  return (
                    <>
                      {item}
                      <br />
                    </>
                  );
                })
              : ""}
          </div>
        );
      },
    },
    {
      title: "Opted In for Beta",
      dataIndex: "response",
      key: "beta_group",
      className: "center",
      width: 160,
      render: (val, record) => {
        return record.response.beta_group ? (
          <CheckCircleFilled style={{ color: "var(--color-success)" }} />
        ) : (
          <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
        );
      },
    },
    {
      title: "",
      dataIndex: "dummy",
      key: "dummy",
      width: 'auto',
    },
  ];

  return (
    <>
      <div className="people-editor">
        <TopSection count={userResponseDataCount} showNewButton={false} />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={userResponseData}
            count={userResponseDataCount}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => rec.id}
          />
        </Spin>
      </div>
    </>
  );
}

export default UserSetupResponse;
