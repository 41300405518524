import './index.scss';

import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';

const ForgotPasswordSent = () => {
  const [isRedirect, setIsRedirect] = useState(false);

  if (isRedirect) {
    return <Navigate to="/user/login" />;
  }

  return (
    <div className="forgot-password-sent-container">
      <div>
        <CheckCircleFilled className="tick-icon" />
      </div>

      <div className="title">
        Password reset instructions sent.
      </div>
      <div className="description">
        Please check your email for a link to reset your password.
      </div>

      <button
        type="submit"
        className="submit-button"
        onClick={() => {
          setIsRedirect(true);
        }}
        style={{ maxWidth: 'max-content' }}
      >
        Back to Home
      </button>
    </div>
  )
}

export default ForgotPasswordSent;
