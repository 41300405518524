import "../index.scss";

import React, { useEffect, useState, useCallback } from "react";
import { Spin, Popconfirm, message, Button } from "antd";
import moment from "moment";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { getValue } from "../../utils/general";
import { requestWithAuth } from "../../services/api";
import { splitNumberWithCommas } from "../../utils/general";
import CommonTable from "../../components/CommonTable";
import TopSection from "../../components/TopSection";
import {
  importExportStatusList,
  importExportSourceList,
} from "../../utils/constants";


let params = {};
function ContactExport(props) {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: "",
    order: "",
  });
  const { column, order } = sortInfo;
  const [filters, setFilters] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [exportDataCount, setExportDataCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchExportData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;

    requestWithAuth("vault-contact-export", "GET", newParams, null).then(
      (response) => {
        if (response && response.results) {
          setExportData(response.results);
          setExportDataCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      }
    );
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    fetchExportData();
   
  }, [fetchExportData, filters, column, order]);
  useEffect(()=>{
    return () => {
      params = {};   }
    },[])

      // setSortInfo({
      //   column: '',
      //   order: ''
      // });
      // setSearchQuery('');
      // setFilters(null);
 

  
  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    Object.keys(filtersArg).reduce((obj, key) => {
      //const filt = { ...obj };
      let value = "";
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);
      if (key === "source" || key === "status") {
        if (!value) {
          if (filters && filters[key]) {
            delete filters[key];
            setFilters({ ...filters });
          }
          delete params[key];
        } else {
          //filt[key] = value.split('\n').join(',');
          setFilters({ ...filters, [key]: value.split(",") });
          // setFilteredInfoSource(value.split(','));
          params[key] = value;
        }
      }
      return null;
    }, {});

    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === "descend" ? "-" + sorter.columnKey : sorter.columnKey;
              setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: "",
        order: "",
      });
      delete params.ordering;
    }
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchExportData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleDelete = (e, recordId) => {
    const body = {};
    if (recordId) {
      body.contact_export_ids = [recordId];
    } else {
      body.contact_export_ids = selectedRowKeys;
    }

    setIsLoading(true);
    requestWithAuth(
      "vault-contact-export/delete-multiple",
      "POST",
      null,
      body
    ).then((response) => {
      if (response && response.code === 200) message.success("Entry deleted");
      setSelectedRowKeys([]);
      setIsLoading(false);
      fetchExportData();
    });
  };

  const handleSearchQuery = (searchValue) => {
  
      setSearchQuery(searchValue || "");
      setListPagination((prevState) => ({
        ...prevState,
        current: 1,
      }));
    
  };

  const handleFormReset = () => {
    params = {};
    setSortInfo({
      column: "",
      order: "",
    });
    setListPagination({
      current: 1,
      pageSize: 50,
    });
    setSearchQuery("");
    setFilters(null);
    // fetchExportData();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys([...selectedRowKeys]);
    },
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      className: "word-break",
    },
    {
      title: "Owner",
      dataIndex: "email",
      key: "email",
      width: 200,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "email" && sortInfo.order,
      className: "word-break",
    },
    {
      title: "Contact Import",
      dataIndex: "contact_import",
      key: "contact_import",
      width: 200,
      className: "word-break",
      render: (val) => {
        return val ? `Contact Import Object(${val})` : "-";
      },
    },
    {
      title: "Upload Path",
      width: 200,
      dataIndex: "upload_path",
      key: "upload_path",
      className: "word-break",
      hidden: props.isNexisAdmin,
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "file_name" && sortInfo.order,
      className: "word-break",
    },
    {
      title: "Count",
      width: 120,
      dataIndex: "count",
      key: "count",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "count" && sortInfo.order,
      render: (val) => {
        return splitNumberWithCommas(val);
      },
    },
    {
      title: "Charge Credits",
      width: 130,
      dataIndex: "charge_credits",
      key: "charge_credits",
      render: (val) => {
        return splitNumberWithCommas(val);
      },
    },
    {
      title: "Source",
      width: 100,
      dataIndex: "source",
      key: "source",
      filters: importExportSourceList,
      filteredValue: filters && filters.source ? filters.source : [],
      filterMultiple: true,
    },
    {
      title: "Status",
      width: 100,
      dataIndex: "status",
      key: "status",
      className: "capitalize",
      filters: importExportStatusList,
      filteredValue: filters && filters.status ? filters.status : [],
      filterMultiple: true,
    },
    {
      title: "Request Params",
      width: 200,
      dataIndex: "request_params",
      key: "request_params",
      className: "word-break",
      hidden: props.isNexisAdmin,
      render: (val) => {
        return val ? <pre>{JSON.stringify(val, null, 2)}</pre> : "";
      },
    },
    {
      title: "Export Headers",
      width: 150,
      dataIndex: "export_headers",
      key: "export_headers",
      className: "word-break",
      hidden: props.isNexisAdmin,
      render: (val) => {
        return val ? <pre>{JSON.stringify(val, null, 2)}</pre> : "";
      },
    },
    {
      title: "Emails Validated",
      dataIndex: "emails_validated",
      key: "emails_validated",
      className: "center",
      hidden: props.isNexisAdmin,
      width: 160,
      render: (val) => {
        return val ? (
          <CheckCircleFilled style={{ color: "var(--color-success)" }} />
        ) : (
          <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
        );
      },
    },
    {
      title: "Phones Validated",
      dataIndex: "phones_validated",
      key: "phones_validated",
      className: "center",
      hidden: props.isNexisAdmin,
      width: 160,
      render: (val) => {
        return val ? (
          <CheckCircleFilled style={{ color: "var(--color-success)" }} />
        ) : (
          <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
        );
      },
    },
    {
      title: "Profiles Refreshed",
      dataIndex: "profiles_refreshed",
      key: "profiles_refreshed",
      className: "center",
      hidden: props.isNexisAdmin,
      width: 160,
      render: (val) => {
        return val ? (
          <CheckCircleFilled style={{ color: "var(--color-success)" }} />
        ) : (
          <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
        );
      },
    },
    // {
    //   title: 'Enrich Type',
    //   width: 120,
    //   dataIndex: 'enrich_type',
    //   key: 'enrich_type',
    //   className: 'capitalize',
    // },
    {
      title: "Household Match",
      dataIndex: "household_match",
      key: "household_match",
      className: "center",
      hidden: props.isNexisAdmin,
      width: 160,
      render: (val) => {
        return val ? (
          <CheckCircleFilled style={{ color: "var(--color-success)" }} />
        ) : (
          <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
        );
      },
    },
    {
      title: "Created",
      width: 120,
      dataIndex: "created",
      key: "created",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "created" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Owner Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 200,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortInfo.column === "first_name" && sortInfo.order,
      className: "word-break",
      render: (val, rec) => {
        return `${rec.first_name} ${rec.last_name || ""}`;
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <>
          <Popconfirm
            title="Delete?"
            onConfirm={(e) => handleDelete(e, record.id)}
          >
            <Button className="row-actions" onClick={(e) => e.preventDefault()}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={exportDataCount}
          placeholder="Search Owner, File Name "
          showNewButton={false}
          showSearch
          selectedRowKeys={selectedRowKeys}
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          handleDeleteMultiple={handleDelete}
          searchValue={searchQuery}
        />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={columns.filter((column) => !column.hidden)}
            data={exportData}
            count={exportDataCount}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => rec.id}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
          />
        </Spin>
      </div>
    </>
  );
}

export default ContactExport;
