import "./index.scss";

import React from "react";
import { Popconfirm, Input, Button } from "antd";
import { DebounceInput } from "react-debounce-input";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

import { splitNumberWithCommas } from "../../utils/general";

const { Search } = Input;
const TopSection = (props) => {
  const {
    count = null,
    placeholder = "",
    showNewButton = true,
    showExportCsv = false,
    showSearch,
    selectedRowKeys,
    handleSearchQuery,
    handleFormReset,
    renderExportComponent,
    toggleCreateForm,
    handleDeleteMultiple,
    deleteButtonText = "Delete",
    searchValue,
  } = props;

  //const [searchValue, setSearchValue] = useState("");
  const onSearch = (val) => {
    handleSearchQuery(val);
  };
  const onClearFilters = () => {
    //setSearchValue("");
    handleFormReset();
  };
  return (
    <div className="table-header">
      <div style={{ display: "inline-flex", gap: "20px" }}>
        {showNewButton && (
          <div>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                toggleCreateForm(true);
              }}
              className="new-button"
            >
              New
            </Button>
          </div>
        )}
        {showExportCsv && <div>{renderExportComponent()}</div>}
        {selectedRowKeys && selectedRowKeys.length > 0 && (
          <Popconfirm
            title={`${deleteButtonText}?`}
            placement="rightBottom"
            onConfirm={handleDeleteMultiple}
          >
            <Button type="primary" className="new-button">
              {deleteButtonText}
            </Button>
          </Popconfirm>
        )}
      </div>
      {showSearch && (
        <>
          <div style={{ marginLeft: "auto", width: "100%", maxWidth: "350px" }}>
            <DebounceInput
              debounceTimeout={500}
              element={Search}
              placeholder={placeholder}
              autoComplete="__away"
              onChange={(e) => onSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") onSearch(e.target.value);
              }}
              enterButton
              value={searchValue}
            />
          </div>
          <div style={{ marginRight: "auto" }}>
            <Button
              icon={<CloseOutlined />}
              onClick={onClearFilters}
              className="clear"
            >
              Clear Filters
            </Button>
          </div>
        </>
      )}
      {count !== null && (
        <div style={{ color: "var(--color-primary)", textAlign: "end" }}>
          {splitNumberWithCommas(count)} {count > 1 ? "Results" : "Result"}
        </div>
      )}
    </div>
  );
};

export default TopSection;
