import "../index.scss";

import React, { useEffect, useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Form,
  Popconfirm,
  Input,
  DatePicker,
  Button,
  message,
  Spin,
  Modal,
  Select,
  InputNumber,
  Checkbox,
  Tag,
  Space,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  KeyOutlined,
  SearchOutlined,
  UnlockOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import moment from "moment";

import { requestWithAuth } from "../../services/api";
import {
  filterStates,
  // userTypeFilters,
  industryList,
} from "../../utils/constants";
import { getValue, getCrmLabels, getCrmLogo } from "../../utils/general";
import CommonTable from "../../components/CommonTable";
import TopSection from "../../components/TopSection";
// import { EditableCell, EditableRow } from "../../components/EditableComponents";
import CompanySuggest from "../../components/CompanySuggest";
import ExportCsv from "../../components/ExportCsv";

let params = {};

moment.fn.toJSON = function () {
  return this.format("YYYY-MM-DD");
};

const FormItems = (props) => {
  const {
    formVals,
    form,
    isUpdate,
    userTypes,
    isNexisAdmin,
    referrerCodes,
    accountRep,
    theme,
  } = props;

  const [isNexisUser, setIsNexisUser] = useState(isNexisAdmin ? true : false);
  const [userTypeData, setUserTypeData] = useState([]);

  useEffect(() => {
    form.resetFields();
    if (formVals.is_nexis_user) setIsNexisUser(true);
  }, [formVals, form]);

  useEffect(() => {
    if (isNexisUser) {
      setUserTypeData([...userTypes.filter((type) => type.value !== "admin")]);
    } else setUserTypeData([...userTypes]);
  }, [isNexisUser, userTypes]);

  /**state update on editing a record */
  // useEffect(() => {
  //   if (formVals.is_nexis_user) setIsNexisUser(true);
  // }, [formVals]);

  return (
    <>
      <Form.Item
        name="first_name"
        label="First name"
        initialValue={formVals.first_name}
        required
        rules={[
          {
            required: true,
            message: "Enter first name",
          },
        ]}
      >
        <Input placeholder="First name" autoComplete="_away" />
      </Form.Item>

      <Form.Item
        name="last_name"
        label="Last name"
        initialValue={formVals.last_name}
        required
        rules={[
          {
            required: true,
            message: "Enter last name",
          },
        ]}
      >
        <Input placeholder="Last name" autoComplete="_away" />
      </Form.Item>

      <Form.Item name="title" label="Title" initialValue={formVals.title}>
        <Input placeholder="Title" />
      </Form.Item>

      <Form.Item
        name="us_state_code"
        label="State"
        initialValue={formVals.us_state_code}
      >
        <Select style={{ width: "100%" }}>
          {filterStates.map(({ value, text }) => (
            <Select.Option key={value} value={text}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="org_name"
        label="Org Name"
        initialValue={formVals.org_name}
      >
        <CompanySuggest
          value={{ label: formVals.org_name, value: formVals.ai_org_id }}
        />
      </Form.Item>

      <Form.Item
        name="industry"
        label="Industry"
        initialValue={formVals.industry}
      >
        <Select style={{ width: "100%" }}>
          {industryList.map(({ value, text }) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <div className="nexis-checkbox">
        <Form.Item
          name="is_nexis_user"
          label="Nexis User"
          initialValue={isNexisAdmin ? true : formVals.is_nexis_user || false}
          valuePropName="checked"
        >
          <Checkbox
            disabled={isNexisAdmin}
            defaultChecked={isNexisAdmin}
            onChange={(e) => setIsNexisUser(e.target.checked)}
          />
        </Form.Item>

        {isNexisUser && (
          <Form.Item
            name="is_nexis_admin"
            label="Nexis Admin App Access"
            initialValue={formVals.is_nexis_admin || false}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        )}
      </div>
      <Form.Item
        name="email"
        extra="Please enter valid email. A password reset link will be sent to this email"
        label="Email"
        initialValue={formVals.email}
        required
        rules={[
          {
            required: true,
            message: "Enter email",
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      {!isNexisAdmin && (
        <Form.Item
          name="is_staff"
          label="AI Admin App Access"
          initialValue={formVals.is_staff}
          valuePropName="checked"
        >
          {/* <Input type="checkbox" placeholder="Staff Status" /> */}
          <Checkbox disabled={isNexisUser} />
        </Form.Item>
      )}

      <Form.Item
        name="user_type"
        label="User Type"
        initialValue={formVals.user_type}
      >
        <Select style={{ width: "100%" }}>
          {userTypeData.map(({ value, text }) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {!isNexisAdmin && (
        <>
          <Form.Item
            name="b2b_id"
            label="b2b_id"
            initialValue={formVals.b2b_id}
          >
            <Input placeholder="b2b_id" />
          </Form.Item>

          <Form.Item
            name="b2c_id"
            label="b2c_id"
            initialValue={formVals.b2c_id}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="b2c_id"
              autoComplete="_away"
            />
          </Form.Item>

          <Form.Item
            name="linkedin_url"
            label="LinkedIn URL"
            initialValue={formVals.linkedin_url}
          >
            <Input
              style={{ width: "100%" }}
              placeholder="LinkedIn URL"
              autoComplete="_away"
            />
          </Form.Item>


          <Form.Item
            name="maxio_subscription_state"
            label="Maxio subscription state"
            initialValue={formVals.maxio_subscription_state}
          >
            <Input placeholder="Maxio subscription state" disabled />
          </Form.Item>

          <Form.Item
            name="maxio_subscription_expiration"
            label="Maxio subscription expiration date"
            initialValue={formVals.maxio_subscription_expiration}
          >
            <Input placeholder="Maxio subscription expiration date" disabled />
          </Form.Item>

          <Form.Item
            name="is_paywalled"
            label="User is paywalled"
            initialValue={formVals.is_paywalled}
          >
            <Input placeholder="Maxio subscription expiration date" disabled />
          </Form.Item>

          <Form.Item
            name="user_maxio_id"
            label="User maxio customer ID"
            initialValue={formVals.user_maxio_id}
          >
            <Input placeholder="User maxio customer ID" disabled />
          </Form.Item>
        </>
      )}

      <Form.Item name="credits" label="Credits" initialValue={formVals.credits}>
        <InputNumber style={{ width: "100%" }} placeholder="Credits" />
      </Form.Item>

      <Form.Item
        name="is_corporate"
        label="Invoice Only"
        initialValue={formVals.is_corporate}
        valuePropName="checked"
      >
        {/* <Input type="checkbox" placeholder="Corporate" /> */}
        <Checkbox />
      </Form.Item>
      {!isNexisAdmin && (
        <Form.Item
          name="theme"
          label="White Label"
          initialValue={formVals.theme}
        >
          <Select style={{ width: "100%" }}>
            {theme.map(({ theme__id, theme__name }) => (
              <Select.Option key={theme__id} value={theme__id}>
                {theme__name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        name="create_time"
        label="Created"
        initialValue={
          formVals.create_time ? moment(formVals.create_time) : undefined
        }
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" disabled />
      </Form.Item>

      <Form.Item
        name="last_login"
        label="Last Access"
        initialValue={
          formVals.last_login ? moment(formVals.last_login) : undefined
        }
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" disabled />
      </Form.Item>

      {!isNexisAdmin && (
        <>
          <Form.Item
            name="inactive_date"
            label="Inactive Date"
            initialValue={
              formVals.inactive_date
                ? moment(formVals.inactive_date)
                : undefined
            }
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" disabled />
          </Form.Item>

          <Form.Item
            name="user_data_deletion_date"
            label="User Data Deletion Date"
            initialValue={
              formVals.user_data_deletion_date
                ? moment(formVals.user_data_deletion_date)
                : undefined
            }
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" disabled />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="user_ref_code"
        label="User Ref Code"
        // extra={expiryDate && <div className=>User Ref Code Expiration:{' ' + moment(expiryDate).format('MM/DD/YY')}</div>}
        initialValue={formVals.user_ref_code}
        required
        rules={[
          {
            required: true,
            message: "Enter User Ref Code",
          },
        ]}
      >
        <Select showSearch allowClear style={{ width: "100%" }}>
          {referrerCodes.map(({ value, expiry }) => (
            <Select.Option key={value} value={value} expiry={expiry}>
              <div className="select-class">
                <div>{value}</div>
                {expiry && (
                  <div className="sub-option">
                    EXP: {" " + moment(expiry).format("MM/DD/YY")}
                  </div>
                )}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="account_rep"
        label="Account Rep"
        required
        rules={[
          {
            required: true,
            message: "Enter User Ref Code",
          },
        ]}
        // initialValue={formVals.account_rep}>
        initialValue={
          accountRep.some((el) => el.id === formVals.account_rep)
            ? formVals.account_rep
            : ""
        }
      >
        <Select
          style={{ width: "100%" }}
          filterSort={(a, b) => {
            return a.children > b.cildren
              ? 1
              : b.children > a.children
              ? -1
              : 0;
          }}
        >
          {accountRep.map(({ id, email }) => (
            <Select.Option key={id} value={id}>
              {email}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {isUpdate && (
        <>
          <Form.Item name="crm" label="CRM">
            <div className="crm-container">
              <div>{getCrmLabels(formVals.crm)}</div>
              {getCrmLogo(formVals.crm) && (
                <img
                  src={getCrmLogo(formVals.crm)}
                  alt={getCrmLabels(formVals.crm)}
                />
              )}
            </div>
          </Form.Item>

          <Form.Item name="hubspot_contact_id" label="Hubspot ID" initialValue={formVals.hubspot_contact_id}>
            <Input placeholder="Hubspot ID"/>
          </Form.Item>
          <Form.Item name="id" label="User ID">
            <div className="crm-container">{formVals.id}</div>
          </Form.Item>
          <Form.Item
            name="first_saved_search"
            label="Created First Saved Search"
          >
            {formVals.first_saved_search ? (
              <CheckCircleFilled style={{ color: "var(--color-success)" }} />
            ) : (
              <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
            )}
          </Form.Item>
          <Form.Item name="import_contacts" label="Imported Contacts">
            {formVals.import_contacts ? (
              <CheckCircleFilled style={{ color: "var(--color-success)" }} />
            ) : (
              <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
            )}
          </Form.Item>
          <Form.Item name="daily_emails" label="Created Email Triggers">
            {formVals.daily_emails ? (
              <CheckCircleFilled style={{ color: "var(--color-success)" }} />
            ) : (
              <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
            )}
          </Form.Item>
          <Form.Item
            name="warm_intros_movie"
            label="Watched Getting Started Video"
          >
            {formVals.warm_intros_movie ? (
              <CheckCircleFilled style={{ color: "var(--color-success)" }} />
            ) : (
              <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
            )}
          </Form.Item>
          <Form.Item name="feeder_list_movie" label="Watched Prospecting Video">
            {formVals.feeder_list_movie ? (
              <CheckCircleFilled style={{ color: "var(--color-success)" }} />
            ) : (
              <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
            )}
          </Form.Item>
          <Form.Item
            name="referral_credits"
            label="Made Referrals to Earn Credits"
          >
            {formVals.referral_credits ? (
              <CheckCircleFilled style={{ color: "var(--color-success)" }} />
            ) : (
              <CloseCircleFilled style={{ color: "var(--color-danger)" }} />
            )}
          </Form.Item>
        </>
      )}
    </>
  );
};

const FormModal = ({
  form,
  isUpdate,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
  userTypes,
  isNexisAdmin,
  theme,
  referrerCodes,
  accountRep,
  error,
}) => {
  const [formVals, setFormVals] = useState({});

  /** put nexis admin status fron api response to nexis checkbox values,
   * as nexis_admin is a single key derived from is_nexis_user & is_nexis_admin
   * checkbox values
   */
  useEffect(() => {
    if (isUpdate) {
      let values = { ...recordFormVals };
      if (values.nexis_admin) {
        values.is_nexis_user = true;
        values.is_nexis_admin = true;
      } else if (values.nexis_admin === false) {
        values.is_nexis_user = true;
        values.is_nexis_admin = false;
      } else {
        values.is_nexis_user = false;
        values.is_nexis_admin = false;
      }

      setFormVals({ ...values });
    }
  }, [recordFormVals, isUpdate]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };

        /** by default, is_nexis_user = true for nexis admin,
         * api key nexis_admin is derived from is_nexis_user &&
         * is_nexis_admin checkbox inputs as:
         * !is_nexis_user && !is_nexis_admin --> null,
         * is_nexis_user && is_nexis_admin --> true,
         * is_nexis_user && !is_nexis_admin --> false,
         */
        if (isNexisAdmin) formValues.is_nexis_user = true;
        if (!formValues.is_nexis_user && !formValues.is_nexis_admin)
          formValues.nexis_admin = null;
        else
          formValues.nexis_admin =
            formValues.is_nexis_user && formValues.is_nexis_admin;

        delete formValues.is_nexis_user;
        delete formValues.is_nexis_admin;

        if (formValues.org_name && formValues.org_name.label) {
          // formValues.ai_org_id = formValues.org_name.key;
          formValues.org_name = formValues.org_name.label;
        } else {
          delete formValues.org_name;
        }
        if (formValues.credits)
          formValues.credits = formValues.credits.toString();
        else delete formValues.credits;

        if (isUpdate) {
          handleUpdate(formVals.id, formValues);
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error("Please fill the mandatory fileds");
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? "Update User" : "New User"}
      visible
      width={800}
      okText={isUpdate ? "Update" : "Create"}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="medium" spinning={isModalLoading}>
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 13 }}
          form={form}
          onFieldsChange={(changedFields, allFields) => {
            if (
              changedFields[0].name[0] === "is_nexis_user" &&
              changedFields[0].value
            ) {
              const touchedFields = allFields.filter(
                (field) => field.touched && field.name[0] !== "is_nexis_user"
              );
              const formValChanged = touchedFields.reduce(
                (accumulator, currentValue) => {
                  return {
                    ...accumulator,
                    [currentValue.name[0]]: currentValue.value,
                  };
                },
                {}
              );

              setFormVals({
                ...formVals,
                ...formValChanged,
                is_nexis_user: true,
                is_staff: false,
                user_type:
                  formVals.user_type === "admin"
                    ? "premium"
                    : formVals.user_type,
              });
            }
          }}
        >
          <FormItems
            formVals={formVals}
            form={form}
            isUpdate={isUpdate}
            userTypes={userTypes}
            isNexisAdmin={isNexisAdmin}
            accountRep={accountRep}
            theme={theme}
            referrerCodes={referrerCodes}
          />
          {error && <div className="error-text">{error}</div>}
        </Form>
      </Spin>
    </Modal>
  );
};

const UserEditor = ({ isNexisAdmin }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 20,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [sorterState, setSorterState] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userTypesData, setUserTypesData] = useState([]);
  const [filtersState, setFiltersState] = useState({});
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [selectedIds, setSelectedIds] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [theme, setTheme] = useState([]);
  const [referrerCodes, setReferrerCodes] = useState([]);
  const [activeReferrerCodes, setActiveReferrerCodes] = useState([]);
  const [accountRep, setAccountRep] = useState([]);
  const [error, setError] = useState(null);

  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();

  useEffect(() => {
    const errorListner = (e) => {
      /** In order to hide error "ResizeObserver loop completed with undelivered notifications."
       * caused during antd form size within Popconfirm
       *  */
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
      }
    };
    window.addEventListener("error", errorListner);
    return () => {
      window.removeEventListener("error", errorListner);
    };
  }, []);

  const fetchUsersData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      ...Object.fromEntries(searchParams),
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth("vault-user", "GET", newParams, null).then((response) => {
      if (response && response.results) {
        setUsersData(response.results);
        setUsersCount(response.count);
        setListPagination((prevState) => ({
          ...prevState,
          total: response.count,
        }));
        setIsLoading(false);
      }
    });
  }, [pageSize, current, searchQuery, searchParams]);

  useEffect(() => {
    fetchUsersData();
  }, [fetchUsersData, filtersState, sorterState]);
  useEffect(() => {
    fetchTheme();
    fetchAccountRep();
    fetchReferrerCodes();
    fetchReferrerCodes(true);
    fetchUserType();
    return () => {
      params = {};
    };
  }, []);

  const fetchUserType = () => {
    const params = {
      key: "user_type",
    };
    requestWithAuth("field-lookup", "GET", params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const userType = [];
          response.result.map((user) => {
            userType.push({ value: user, text: user });
            return null;
          });
          setUserTypesData(userType);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchTheme = () => {
    const params = {
      key: "theme",
    };
    requestWithAuth("field-lookup", "GET", params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          setTheme(response.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchAccountRep = () => {
    const params = {
      key: "account_rep",
    };
    requestWithAuth("field-lookup", "GET", params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          setAccountRep(response.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchReferrerCodes = (isActive) => {
    const params = {
      key: "referrer_codes_lookup",
    };
    if (isActive) params.active_ref_codes = 1;
    requestWithAuth("field-lookup", "GET", params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const referrer = [];
          response.result.map((code) => {
            referrer.push({
              value: code.referrer_code,
              text: code.referrer_code,
              expiry: code.expiry_date !== null ? code.expiry_date : "",
            });
            return null;
          });
          referrer.sort(function (x, y) {
            let a = x.value.toUpperCase(),
              b = y.value.toUpperCase();
            return a === b ? 0 : a > b ? 1 : -1;
          });
          if (isActive) setActiveReferrerCodes(referrer);
          else setReferrerCodes(referrer);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || "");
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };
  const handleFormReset = () => {
    params = {};
    setFiltersState({});
    setSorterState([]);
    setListPagination({
      current: 1,
      pageSize: 50,
    });
    setSearchQuery("");
    setSearchParams({});
  };

  const handleCreate = (fields) => {
    setIsModalLoading(true);
    setError(null);
    requestWithAuth("vault-user", "POST", null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(", "));
          // setError(Object.keys(response.error).map((key) => [key, response.error[key]].join(":")).join(", "))
          setIsModalLoading(false);
          return;
        } else if (response) {
          fetchUsersData();
          setShowFormModal(false);
        }

        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUpdate = (recordId, fields) => {
    setError(null);
    setIsModalLoading(true);
    requestWithAuth(`vault-user/${recordId}`, "PUT", null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(", "));
          // setError(Object.keys(response.error).map((key) => [key, response.error[key]].join(":")).join(", "))
          setIsModalLoading(false);
          return;
        } else if (response) {
          fetchUsersData();
          setShowFormModal(false);
          setIsUpdate(false);
          fetchAccountRep();
        }

        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  // const handleDelete = (recordId) => {
  //   setIsLoading(true);
  //   requestWithAuth("vault-user/" + recordId, "DELETE", null, null).then(
  //     (response) => {
  //       if (response && response.code === 200) message.success("User deleted");
  //       setIsLoading(false);
  //       fetchUsersData();
  //     }
  //   );
  // };

  // const handleDeleteMultiple = () => {
  //   const body = {
  //     user_ids: selectedIds,
  //   };
  //   setIsLoading(true);
  //   requestWithAuth("vault-user-delete", "POST", null, body).then(
  //     (response) => {
  //       if (response && response.code === 200) message.success("User deleted");
  //       setSelectedIds([]);
  //       setSelectedRowKeys([]);
  //       setIsLoading(false);
  //       fetchUsersData();
  //     }
  //   );
  // };

  // const onSelectChange = (selectedRowKeys, selectedRows) => {
  //   let selectedIds = [];
  //   selectedIds = selectedRows.map((obj) => {
  //     return obj.id;
  //   });
  //   setSelectedRowKeys(selectedRowKeys);
  //   setSelectedIds(selectedIds);
  // };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    // setSelectedRowKeys([]);
    setFiltersState(filtersArg);
    Object.keys(filtersArg).reduce((obj, key) => {
      const filterObj = { ...obj };
      let value = "";
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);

      if (key === "is_staff") {
        if (!value || value === "all") {
          delete params.is_staff;
        } else {
          params.is_staff = value === "true" ? 1 : 0;
        }
      } else if (key === "user_type") {
        if (!value) {
          delete params.user_type;
        } else {
          filterObj.user_type = value;
          params.user_type = value;
        }
      } else if (key === "user_ref_code_expiry_date") {
        // if length is greater than 1, it means both true and false are selected
        if (!value || filtersArg[key]?.length > 1) {
          delete params.user_ref_code_expiry_date__isnull;
        } else {
          params.user_ref_code_expiry_date__isnull = value;
        }
      } else if (key === "contacts_matched") {
        // if length is greater than 1, it means both true and false are selected
        if (!value || filtersArg[key]?.length > 1) {
          delete params.contacts_matched__isnull;
        } else {
          params.contacts_matched__isnull = value;
        }
      } else if (key === "is_corporate") {
        if (!value) {
          delete params.is_corporate;
        } else {
          params.is_corporate = value;
        }
      } else if (key === "b2b_id") {
        if (value !== "true" && value !== "false") {
          delete params.b2b_id__isblank;
        } else {
          params.b2b_id__isblank = value;
        }
      }

      return null;
    }, {});
    sorter = Array.isArray(sorter) ? sorter : [sorter];
    setSorterState(sorter);
    // Remove all items from sorter that don't have an order
    sorter = sorter.filter((item) => item.order);
    if (sorter.length > 0) {
      params.ordering = sorter
        .map((info) =>
          info.order === "descend" ? `-${info.columnKey}` : info.columnKey
        )
        .join(",");
    } else {
      delete params.ordering;
    }
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchUsersData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  // const components = {
  //     body: {
  //         row: EditableRow,
  //         cell: EditableCell,
  //     },
  // };

  function unlock(id) {
    requestWithAuth(`vault-user/${id}/unlock_auth0`, "POST", null, null).then(
      (response) => {
        if (response && response.code === 200)
          message.success("Profile unlocked");
        else
          message.error("Not able to unlock now. Please try after some time");
      }
    );
  }

  const onFinish = (id) => {
    const values = passwordForm.getFieldsValue();
    if (values && values.password) {
      requestWithAuth(`vault-user/${id}/change_auth0_password`, "POST", null, {
        new_password: values.password,
      }).then((response) => {
        console.log(response);
        if (response && response.statusCode === 200)
          message.success("Password Updated");
        else if (response.message) {
          message.error({
            content: response.message.replace("*", "").replace(/ \*/g, ","),
            style: { maxWidth: "600px", margin: "auto" },
          });
        } else {
          message.error(
            "Not able to change Password now. Please try after some time"
          );
        }
      });
    }
    passwordForm.resetFields();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex.replace(/_/g, " ")}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleColumnSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() =>
            handleColumnSearchReset(clearFilters, confirm, dataIndex)
          }
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filteredValue: filtersState[dataIndex] || [],
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "var(--color-primary)" : undefined }}
      />
    ),
  });

  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    let searchText = selectedKeys[0];
    if (
      [
        "account_rep_email",
        "email",
        "first_name",
        "last_name",
        "user_ref_code",
      ].includes(dataIndex)
    ) {
      params[dataIndex] = searchText;
    }
  };

  const handleColumnSearchReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    setFiltersState((prevState) => {
      const newState = { ...prevState };
      delete newState[dataIndex];
      return newState;
    });
    if (
      [
        "account_rep_email",
        "email",
        "first_name",
        "last_name",
        "user_ref_code",
      ].includes(dataIndex)
    ) {
      delete params[dataIndex];
    }
    confirm();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      sorter: { multiple: 8 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "id")?.order,
      render: (val) => {
        return (
          <div style={{ width: "100%", wordBreak: "break-word", display: "block" }}>
            {val}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      sorter: { multiple: 3 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "email")?.order,
      ...getColumnSearchProps("email"),
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 120,
      sorter: { multiple: 2 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "first_name")
        ?.order,
      ...getColumnSearchProps("first_name"),
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: 120,
      sorter: { multiple: 1 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "last_name")
        ?.order,
      ...getColumnSearchProps("last_name"),
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "B2B ID",
      dataIndex: "b2b_id",
      key: "b2b_id",
      width: 120,
      sorter: false,
      filters: [
        { text: "N/A", value: true },
        { text: "Not N/A", value: false },
      ],
      filterMultiple: false,
      filteredValue: filtersState.b2b_id || [],
      render: (val) => {
        if (!val) return null;
        const displayValue = val.length > 15 ? val.slice(0, 12) + "..." : val;
        return (
          <Tooltip title={val}>
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {displayValue}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "B2C ID",
      width: 100,
      dataIndex: "b2c_id",
      key: "b2c_id",
      sorter: false,
      render: (_val, rec) => {
        return (
          <div style={{ width: "100%", hyphens: "none" }}>{rec.b2c_id}</div>
        );
      },
    },
    {
      title: "Account Rep",
      dataIndex: "account_rep_email",
      key: "account_rep_email",
      width: 200,
      ...getColumnSearchProps("account_rep_email"),
      sorter: { multiple: 4 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find(
        (info) => info.columnKey === "account_rep_email"
      )?.order,
      render: (val) => {
        if (!val) return null;
        const displayValue = val.length > 30 ? val.slice(0, 27) + "..." : val;
        return (
          <Tooltip title={val}>
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {displayValue}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      width: 120,
      sorter: { multiple: 5 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "user_type")
        ?.order,
      filters: userTypesData,
      filteredValue: filtersState.user_type || [],
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: "Created On",
      width: 100,
      dataIndex: "create_time",
      key: "create_time",
      sorter: { multiple: 6 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "create_time")
        ?.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      width: 160,
      sorter: { multiple: 7 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "last_login")
        ?.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY HH:mm:ss") : "Never"}
          </div>
        );
      },
    },
    {
      title: "Invoiced",
      dataIndex: "is_corporate",
      key: "is_corporate",
      width: 80,
      sorter: { multiple: 8 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "is_corporate")
        ?.order,
      filters: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" },
      ],
      filterMultiple: false,
      filteredValue: filtersState.is_corporate || [],
      render: (val) => (
        <Tag color={val ? "green" : "red"}>{val ? "Yes" : "No"}</Tag>
      ),
    },
    {
      title: "Ref Code",
      dataIndex: "user_ref_code",
      key: "user_ref_code",
      width: 200,
      ...getColumnSearchProps("user_ref_code"),
      sorter: { multiple: 9 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "user_ref_code")
        ?.order,
      render: (val) => {
        return <Link to={`/referrercode?referrer_code=${val}`}><div className="column-style">{val}</div></Link>;
      },
    },
    {
      title: "Ref Code Expiry",
      dataIndex: "user_ref_code_expiry_date",
      key: "user_ref_code_expiry_date",
      width: 120,
      sorter: { multiple: 10 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find(
        (info) => info.columnKey === "user_ref_code_expiry_date"
      )?.order,
      filters: [
        { text: "N/A", value: "true" },
        { text: "Not N/A", value: "false" },
      ],
      filterMultiple: false,
      filteredValue: filtersState.user_ref_code_expiry_date || [],
      render: (val) => {
        return (
          <div className="column-style">
            {val ? moment(val).format("MM/DD/YY") : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Credits",
      dataIndex: "credits",
      key: "credits",
      width: 80,
      sorter: { multiple: 11 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "credits")
        ?.order,
      render: (val) => {
        return <div className="column-style">{val !== null ? val : "N/A"}</div>;
      },
    },
    {
      title: "Contacts Matched",
      dataIndex: "contacts_matched",
      key: "contacts_matched",
      width: 120,
      sorter: { multiple: 12 },
      sortDirections: ["ascend", "descend"],
      sortOrder: sorterState.find((info) => info.columnKey === "contacts_matched")
        ?.order,
      filters: [
        { text: "N/A", value: "true" },
        { text: "Not N/A", value: "false" },
      ],
      filterMultiple: false,
      filteredValue: filtersState.contacts_matched,
      render: (val) => {
        return <div className="column-style">{val !== null ? val.toLocaleString("en-US") : "N/A"}</div>;
      },
    },
    {
      title: "Teams Count",
      dataIndex: "teams_count",
      key: "teams_count",
      width: 100,
      sorter: { multiple: 13 },
      sortDirections: ["descend", "ascend"],
      sortOrder: sorterState.find((info) => info.columnKey === "teams_count")?.order,
      render: (val, rec) => {
        return <Link to={`/team-editor?team_member_user_id=${rec.id}`}><div className="column-style">{val !== null ? val : "0"}</div></Link>;
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Space size="small">
          <Tooltip title="Edit user">
            <Button
              icon={<EditOutlined />}
              onClick={() => showUpdateForm(record)}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Unlock user">
            <Button
              icon={<UnlockOutlined />}
              onClick={() => unlock(record.id)}
              size="small"
            />
          </Tooltip>
          {record && !record.is_staff && (
            <Tooltip title="Change password">
              <Popconfirm
                title={
                  <div className="user-password-change">
                    <b>Change Password</b>
                    <br />
                    <br />
                    <Form form={passwordForm} initialValues={{ password: "" }}>
                      <div>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Password cannot be empty",
                            },
                            {
                              pattern:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
                              message:
                                "Password must have at least 8 characters, contain lower and upper case letters and\n include number(s) and special character(s)",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Password" />
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                }
                icon={null}
                onCancel={() => passwordForm.resetFields()}
                onConfirm={() => onFinish(record.id)}
              >
                <Button icon={<KeyOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const renderExportComponent = () => {
    return (
      <ExportCsv
        totalCount={usersCount}
        searchQuery={searchQuery}
        url="vault-user"
        downloadFileName="aidentified_users"
        filterParams={params}
      />
    );
  };

  return (
    <>
      <div>
        <TopSection
          placeholder="Search name, email, referrer code"
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          toggleCreateForm={toggleFormModal}
          showSearch
          // selectedRowKeys={selectedRowKeys}
          // handleDeleteMultiple={handleDeleteMultiple}
          count={usersCount}
          searchValue={searchQuery}
          showExportCsv={usersCount > 0}
          renderExportComponent={renderExportComponent}
        />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={usersData}
            // components={components}
            rowClassName={() => "editable-row"}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => rec.id}
            // rowSelection={rowSelection}
            // selectedRowKeys={selectedRowKeys}
            rowEventHandlers={showUpdateForm}
            scroll={{ x: "max-content" }}
            size="small"
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          userTypes={userTypesData}
          isNexisAdmin={isNexisAdmin}
          theme={theme}
          referrerCodes={isUpdate ? referrerCodes : activeReferrerCodes}
          accountRep={accountRep}
          error={error}
          onClose={() => {
            form.resetFields();
            setIsUpdate(false);
            setShowFormModal(false);
            setError(null);
          }}
        />
      )}
    </>
  );
};

export default UserEditor;
