import './index.css';

// import ExceptionImg from '../../assets/logo-header.svg';
import React from "react";
import logo from "../../assets/icons/ai-logo.svg";
import logotype from "../../assets/icons/ai-admin-logotype.svg";

class Exception extends React.Component {
  constructor() {
    super();
    this.state = {
      errorCode: null,
      errorMsg: '',
    };
  }

  componentDidMount() {
    let code = 404;
    let msg = 'Sorry, the page you visited does not exist';

    if (this.props.location && this.props.location.pathname.split('/')[1] === 'exception') {
      code = this.props.location.pathname.split('/')[2];
      msg = 'Sorry, the server is reporting an error';
    }
    this.setState({
      errorCode: code,
      errorMsg: msg,
    });
  }

  render() {
    const { errorCode, errorMsg } = this.state;
    return (
      <div className="page-not-found-container">
        <div className="main">
          <div className="left">
            <div className="header">
              <img className="logo" src={logo} alt="logo" />
              <img className="logotype" src={logotype} alt="logotype" />
            </div>
          </div>
          <div className="right">
            <h1>{errorCode}</h1>

            <div className="not-found-subtext">{errorMsg}</div>

            <button type="submit" className="not-found-button">
              <div className="link">
                <a href="/">Back to Home</a>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Exception;
